import React from 'react'
import { Link } from 'gatsby'

import ArnoldWebsite from '../assets/case-studies/arnolds-garage-door-service-website.png'
import SimpleWebsite from '../assets/case-studies/simple-southern-designs-website.png'
import MogotesWebsite from '../assets/case-studies/mogotes-concrete-website.png'


const CompStudies = () => {

  return (

    <section className="compstud-section">

    <h2 className="compstud-heading">
    <Link to="/case-studies">Case Studies</Link>
    </h2>
    

    <div className="compstud-container">
      <div className="compstud-card">
        <div className="compstud-info-side">
          <div>
            <h3 className="compstud-info-heading">Arnold's Garage Door Service</h3>
            <p>Home Improvement &amp; Repair</p>
            <p><em>Service Area Business</em></p>
            {/* <p>Arnold's Garage Door Service is a full service garage door repair and installation company that services the area just south of Fort Worth.</p> */}
            <Link to="/case-studies/arnolds-garage-door-service" className="compstud-info-link">See Case Study &rarr;</Link>
          </div>
        </div>

        <div className="compstud-image-side">
          <img src={ArnoldWebsite} className="compstud-image" alt="Arnold's Garage Door Service Website Screenshot"/>
        </div>
      </div>
    </div>

    <div className="compstud-container">
      <div className="compstud-card">
        <div className="compstud-info-side">
          <div>
            <h3 className="compstud-info-heading">Mogotes Concrete</h3>
            <p>Golf Course, Commercial &amp; Residential Concrete</p>
            <p><em>Service Area Business</em></p>
            {/* <p>Mogotes Concrete is a concrete contractor that specializes </p> */}
            <Link to="/case-studies/mogotes-concrete" className="compstud-info-link">See Case Study &rarr;</Link>
          </div>
        </div>

        <div className="compstud-image-side">
          <img src={MogotesWebsite} className="compstud-image" alt="Mogotes Concrete Website Screenshot"/>
        </div>

      </div>
    </div>

    <div className="compstud-container">
      <div className="compstud-card">
        <div className="compstud-info-side">
          <div>
            <h3 className="compstud-info-heading">Simple Southern Designs</h3>
            <p>Handmade Wedding &amp; Home Decor</p>
            <p><em>E-Commerce</em></p>
            {/* <p>Simple Southern Designs is an online shop based in Fort Worth, TX that sells handmade wedding and home decor. Rosalia is the driving force behind the scenes that hand makes everything and ships it out herself. She does everything from crafts specializing in calligraphy.</p> */}
            <Link to="/case-studies/simple-southern-designs" className="compstud-info-link">See Case Study &rarr;</Link>
          </div>
        </div>

        <div className="compstud-image-side">
          <img src={SimpleWebsite} className="compstud-image" alt="Simple Southern Designs Website Screenshot" />
        </div>
      </div>
    </div>
    </section>

  )
}

export default CompStudies