import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import CompStudies from '../components/comp-studies'

const CaseStudies = () => {

  return (
    <Layout>

      <SEO title="Case Studies"  />
      
      <CompStudies />

    </Layout>
  )
}

export default CaseStudies